import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import { Text } from 'ls-common-client/src/components';
import { Header, Footer } from './common';
import Home from './pages/HomeV2';
import NotFound from './pages/NotFound';
import Success from './pages/Success';
import localsearchLogo from '../images/localsearchLogo.svg';
import { Context } from '../contextV2/AppContext';

const LoadingContainer = styled(Container)`
  background-image: ${props => props.theme.custom.backgroundImage.gradient};
`;

const Layout = () => {
  const {
    user: { loading, user, isPersoniv, isStaff },
  } = useContext(Context);

  return (
    <Router>
      {loading ? (
        <LoadingContainer
          position="fixed"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          transition="all ease 0.5s"
          top="0"
          left="0"
          zIndex="2"
        >
          <Container display="flex" flexDirection="column">
            <img alt="Digital Presence Logo" src={localsearchLogo} />
            <Loader width="200px" />
          </Container>
        </LoadingContainer>
      ) : (
        <Container display="flex" minHeight="100vh" flexDirection="column">
          {user && (isPersoniv || isStaff) ? (
            <>
              <Header />

              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/success">
                  <Success />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
              <Footer />
            </>
          ) : (
            <Text>
              You don&amp;t have access, pleas contact head products if you
              think this is incorrect
            </Text>
          )}
        </Container>
      )}
    </Router>
  );
};

export default Layout;
