import { create } from '../lib/client';
import config from '../config';
import auth from './auth';
import logger from './logger';

const { api } = config;

const instance = create({ api, auth, logger });

export default instance;
