import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { user as userQuery } from '../../../graphql/queries';

const hasRoles = (userRoles, roles) =>
  userRoles.some(({ node: { roleName } }) => roles.includes(roleName));

const useUser = () => {
  const { data: { currentUser: user } = {}, loading, refetch } = useQuery(
    userQuery
  );

  const { userRoles } = user || {};

  const isStaff = useMemo(() => {
    const { edges = [] } = userRoles || {};
    return hasRoles(edges, ['staff']);
  }, [userRoles]);

  const isPersoniv = useMemo(() => {
    const { edges = [] } = userRoles || {};
    return hasRoles(edges, ['personiv']);
  }, [userRoles]);

  return {
    user,
    loading,
    refetch,
    isStaff,
    isPersoniv,
  };
};

export default useUser;
