import { auth as authService } from 'ls-common-client';
import config from '../config';
import logger from './logger';

const { auth, token } = config;

const instance = authService.create({
  auth,
  token,
  logger,
});

export default instance;
