import React, { useContext, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Validator from '../../../../../UI/atoms/Validator';
import Selector from '../../../../../UI/molecules/Selector';
import Label from '../../../../../UI/atoms/Label';
import { Context } from '../../../../../../contextV2/AppContext';
import GooglePlaceAutocomplete from './GooglePlacesAutocomplete';
import { STATES } from '../../../../../../lib/constants';
import FormInputV2 from '../../FormInputV2';

const states = [
  {
    value: '',
    label: 'Please Select',
  },
  ...Object.keys(STATES).map(key => ({
    value: STATES[key],
    label: STATES[key],
  })),
];

const getAddressParts = addressComponents => {
  const placesAddressMap = {
    subpremise: { map: 'streetAddress', key: 'long_name' },
    street_number: { map: 'streetAddress', key: 'long_name' },
    route: { map: 'streetAddress', key: 'long_name' },
    locality: { map: 'suburb', key: 'long_name' },
    administrative_area_level_1: { map: 'state', key: 'short_name' },
    postal_code: { map: 'postcode', key: 'long_name' },
  };

  let parts = {};

  addressComponents.forEach(component => {
    const { types } = component;
    const matchingType = types.find(type => placesAddressMap[type]);
    const { map, key } = placesAddressMap[matchingType] || {};

    if (!map) {
      return;
    }

    if (parts[map]) {
      parts = {
        ...parts,
        [map]: `${parts[map]} ${component[key]}`,
      };
    } else {
      parts = {
        ...parts,
        [map]: component[key],
      };
    }
  });

  return parts;
};

const LocationForm = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [showStates, setShowStates] = useState();

  const {
    register,
    formState: { errors },
    setValue,
    control,
    setError,
    clearErrors,
  } = useFormContext();

  const onAddressSelect = place => {
    const { address_components: addressComponents } = place;

    // Coordinates taken out for FM
    // TODO - Add back in when using profile service.
    const parts = {
      ...getAddressParts(addressComponents),
    };

    clearErrors('location');
    Object.keys(parts).forEach(key => {
      setValue(`location.${key}`, parts[key], { shouldDirty: true });
    });
  };

  const onSuburbSelect = place => {
    // Coordinates taken out for FM
    // TODO - Add back in when using profile service.
    const { address_components: addressComponents } = place;

    const parts = {
      postcode: '',
      ...getAddressParts(addressComponents),
    };

    Object.keys(parts).forEach(key => {
      setValue(`location.${key}`, parts[key], { shouldDirty: true });
    });

    clearErrors('location.suburb');
  };

  return (
    <Container
      display="flex"
      flexWrap="wrap"
      margin="0 -10px"
      flexDirection={mobile ? 'column' : 'row'}
    >
      <Container
        flex="1"
        padding="0px 10px 0 10px"
        minWidth={mobile ? 'unset' : '300px'}
      >
        <Label marginBottom="10px">Business Location</Label>
        <Container marginBottom="10px">
          <GooglePlaceAutocomplete
            types={['address']}
            onSelect={onAddressSelect}
            placeholder="Search Address..."
            paddingLeft="38px"
          />
        </Container>
        <Container marginBottom="10px">
          <FormInputV2
            id="street"
            label="Street *"
            {...register('location.streetAddress')}
            placeholder="Street"
            error={
              errors &&
              errors.location &&
              errors.location.streetAddress &&
              errors.location.streetAddress.message
            }
          />
        </Container>
        <Container marginBottom="10px">
          <Label marginBottom="0px">Suburb</Label>
          <Controller
            control={control}
            name="location.suburb"
            render={({ field: { onChange, value } }) => (
              <GooglePlaceAutocomplete
                types={['(regions)']}
                value={value}
                placeholder="Suburb"
                onChange={e => {
                  setError('location.suburb', {
                    type: 'notSelected',
                    message: 'You must select a suburb from the suggestions',
                  });
                  onChange(e);
                }}
                onSelect={onSuburbSelect}
              />
            )}
          />
          <Validator>
            {errors &&
              errors.location &&
              errors.location.suburb &&
              errors.location.suburb.message}
          </Validator>
        </Container>
        <Container
          display="flex"
          margin="0 -5px"
          flexDirection={mobile ? 'column' : 'row'}
        >
          <Container padding="0 5px" flex="1">
            <Label>State *</Label>
            <Controller
              control={control}
              name="location.state"
              render={({ field: { onChange, value } }) => (
                <Selector
                  value={value}
                  onChange={val => {
                    onChange(val);
                    setShowStates(false);
                  }}
                  onClose={() => setShowStates(false)}
                  data={states}
                  show={showStates}
                  width="100%"
                >
                  {({ label, ref }) => (
                    <EmptyButton
                      ref={ref}
                      onClick={() => setShowStates(!showStates)}
                      display="flex"
                      alignItems="center"
                      width="100%"
                      padding="0 16px"
                      height="48px"
                      fontSize="16px"
                      borderRadius="9px"
                      backgroundColor="#f8f8f8"
                      whiteSpace="nowrap"
                      border="1px solid #c4c4c4"
                      cursor="pointer"
                      color="normal"
                      _focus={{
                        border: '1px solid #97AEFF',
                      }}
                    >
                      {label}
                    </EmptyButton>
                  )}
                </Selector>
              )}
            />
            <Validator>
              {errors &&
                errors.location &&
                errors.location.state &&
                errors.location.state.message}
            </Validator>
          </Container>

          <Container padding="0 5px" flex="1">
            <FormInputV2
              id="postcode"
              label="Postcode *"
              {...register('location.postcode')}
              placeholder="Postcode"
              error={
                errors &&
                errors.location &&
                errors.location.postcode &&
                errors.location.postcode.message
              }
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default LocationForm;
