import React from 'react';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Provider } from '../contextV2/AppContext';
import Layout from './Layout';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  body, html {
    margin: 0;
    font-family: ${props => props.theme.font.circular};
    font-size: ${props => props.theme.fontSize.normal};
    color: ${props => props.theme.text.normal};
    height: 1px;
    min-height: 100%;
    background-color: ${props => props.theme.background.normal};
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    min-height: 100vh;
  }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  button {
    font-family: ${props => props.theme.font.circular};
    border: none;
    background: none;
    &:focus {
      outline: none;
    }
  }
  .icon-generalcaretdownmedium {
    width: auto;
    height: auto;
    line-height: 1em;
    text-indent: 0;
  }  
`;

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider>
      <React.Fragment>
        <GlobalStyle />
        <Layout />
      </React.Fragment>
    </Provider>
  </ThemeProvider>
);

export default App;
