import React from 'react';

const Ghost = props => (
  <svg viewBox="0 0 300 189" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M0 61.553C0 95.379 77.457 122.8 173.006 122.8c95.548 0 173.005-27.421 173.005-61.247 0-33.827-77.457-61.248-173.005-61.248C77.457.305 0 27.726 0 61.553"
      />
      <path
        id="prefix__c"
        d="M2.83.283a2.657 2.657 0 000 5.313h5.488a1.84 1.84 0 011.838 1.838c0 3.943 3.208 7.15 7.152 7.15h4.737a2.656 2.656 0 100-5.312h-4.737a1.842 1.842 0 01-1.84-1.838c0-3.944-3.207-7.151-7.15-7.151H2.83z"
      />
      <path
        id="prefix__e"
        d="M26.861.003C12.739 0 .793 11.858.449 25.926.122 40.994.339 55.873 2.834 70.771a143.283 143.283 0 004.62 17.374c.805 2.265 3.408 4.097 5.694 4.097h.416c2.286 0 3.298-1.795 2.4-4.022-.612-1.52.165-2.74 1.673-2.72 1.508.022 3.204 1.265 3.857 2.768.958 2.204 3.712 3.974 5.998 3.974h.416c2.286 0 3.147-1.733 2.098-3.899-.716-1.479-.01-2.67 1.498-2.649 1.508.022 3.275 1.236 4.032 2.697 1.11 2.142 4.015 3.851 6.301 3.851h.415c2.286 0 2.996-1.672 1.795-3.776-.82-1.436-.184-2.599 1.324-2.578 1.508.022 3.347 1.206 4.207 2.626 1.26 2.08 4.317 3.728 6.604 3.728h.208c2.4 0 2.985-1.691 1.576-3.84-3.137-5.206-5.367-10.322-7.07-16.143-3.37-15.257-1.958-30.34-.228-45.72C52.368 12.109 41.375.003 26.872.003h-.01"
      />
      <linearGradient id="prefix__f" x1="50%" x2="50%" y1="-45.26%" y2="100%">
        <stop offset="0%" stopColor="#98B0FD" />
        <stop offset="100%" stopColor="#2D60FB" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(132.4 .397)">
        <mask id="prefix__g" fill="#fff">
          <use xlinkHref="#prefix__e" />
        </mask>
        <path
          fill="url(#prefix__f)"
          d="M26.861.003C12.739 0 .793 11.858.449 25.926.122 40.994.339 55.873 2.834 70.771a143.283 143.283 0 004.62 17.374c.805 2.265 3.408 4.097 5.694 4.097h.416c2.286 0 3.298-1.795 2.4-4.022-.612-1.52.165-2.74 1.673-2.72 1.508.022 3.204 1.265 3.857 2.768.958 2.204 3.712 3.974 5.998 3.974h.416c2.286 0 3.147-1.733 2.098-3.899-.716-1.479-.01-2.67 1.498-2.649 1.508.022 3.275 1.236 4.032 2.697 1.11 2.142 4.015 3.851 6.301 3.851h.415c2.286 0 2.996-1.672 1.795-3.776-.82-1.436-.184-2.599 1.324-2.578 1.508.022 3.347 1.206 4.207 2.626 1.26 2.08 4.317 3.728 6.604 3.728h.208c2.4 0 2.985-1.691 1.576-3.84-3.137-5.206-5.367-10.322-7.07-16.143-3.37-15.257-1.958-30.34-.228-45.72C52.368 12.109 41.375.003 26.872.003h-.01"
          mask="url(#prefix__g)"
        />
      </g>
      <path
        fill="#1C2B6B"
        d="M137.836 31.577c2.49 1.757 6.547-1.36 4.575-8.298-.099-.345-.599-.436-.757-.058-1.802 4.321-6.785 6.261-3.818 8.356m11.247-9.842c-2.978 1.258 2.36 17.919 10.646 12.664 5.051-3.202.495-6.344-4.631-8.246-3.11-1.154-4.224-5.174-6.015-4.418m2.509 18.181c0 3.16-1.758 5.721-3.927 5.721-2.168 0-3.926-2.561-3.926-5.721s1.758-5.722 3.926-5.722c2.169 0 3.927 2.562 3.927 5.722"
      />
      <path
        fill="#97AEFF"
        d="M162.86 63.382h-4.738c-3.943 0-7.15-3.208-7.15-7.15 0-1.014-.826-1.84-1.84-1.84h-5.488a2.656 2.656 0 110-5.312h5.489c3.943 0 7.151 3.208 7.151 7.151a1.84 1.84 0 001.838 1.838h4.738a2.657 2.657 0 010 5.313"
      />
      <g transform="translate(121.6 48.797)">
        <mask id="prefix__d" fill="#fff">
          <use xlinkHref="#prefix__c" />
        </mask>
        <path
          fill="#97AEFF"
          d="M2.83.283a2.657 2.657 0 000 5.313h5.488a1.84 1.84 0 011.838 1.838c0 3.943 3.208 7.15 7.152 7.15h4.737a2.656 2.656 0 100-5.312h-4.737a1.842 1.842 0 01-1.84-1.838c0-3.944-3.207-7.151-7.15-7.151H2.83z"
          mask="url(#prefix__d)"
        />
      </g>
    </g>
  </svg>
);

export default Ghost;
