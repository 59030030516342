import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import FeedbackForm from 'ls-common-client/src/components/FeedbackForm';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import { Context } from '../../contextV2/AppContext';

const StyledContact = styled.a`
  text-decoration: none;
  color: ${props => props.theme.primary.normal};
`;

const Footer = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [showFeedback, setShowFeedback] = useState();

  return (
    <Container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="80px"
    >
      <Container
        flex="1"
        padding={mobile ? '10px 20px' : '10px'}
        maxWidth="1060px"
        flexDirection="row"
        display="flex"
      >
        <Text fontSize="small">
          <Text fontSize="small" fontWeight="600">
            Having problems?
          </Text>{' '}
          Please{' '}
          <StyledContact
            href="mailto:enquiries@localsearch.com.au"
            target="_blank"
          >
            contact
          </StyledContact>{' '}
          head office or{' '}
          <EmptyButton
            fontSize="small"
            color="text700"
            onClick={() => setShowFeedback(true)}
          >
            send feedback
          </EmptyButton>
          .
        </Text>
      </Container>
      <FeedbackForm
        show={showFeedback}
        onClose={() => setShowFeedback(false)}
        mode={mobile ? 'mobile' : 'desktop'}
        application="Internal Add A Business"
      />
    </Container>
  );
};
export default Footer;
