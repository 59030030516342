import React, { useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Context } from '../../../../../../contextV2/AppContext';
import FormInputV2 from '../../FormInputV2';

const GooglePlaceAutocomplete = ({ onSelect, types, ...props }) => {
  const inputRef = useRef();

  const {
    googleMaps: { isLoaded, init },
  } = useContext(Context);

  useEffect(init, []);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        componentRestrictions: { country: ['au'] },
        fields: ['ALL'],
        types,
      }
    );

    autocomplete.addListener('place_changed', () => {
      onSelect(autocomplete.getPlace());
    });
  }, [isLoaded]);

  return (
    <FormInputV2
      id="streetAddress"
      ref={inputRef}
      autoComplete="new-password"
      onKeyDown={e => {
        const { which } = e;
        if (which === 13) {
          e.preventDefault();
        }
      }}
      {...props}
    />
  );
};

GooglePlaceAutocomplete.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
};

GooglePlaceAutocomplete.defaultProps = {
  types: null,
  onSelect: () => {},
};

export default GooglePlaceAutocomplete;
