import { gql } from '@apollo/client';

const createListingMutation = gql`
  mutation CreateListing($input: CreateListingInput!) {
    createListing(input: $input) {
      success
    }
  }
`;

export default createListingMutation;
