import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Label from './Label';
import { Context } from '../../contextV2/AppContext';

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  top: 2px;
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + span {
    background-color: ${props =>
      props.theme.background[props.backgroundColor] ||
      props.backgroundColor ||
      props.theme.primary.normal};
  }
  &:checked + span:before {
    transform: translateX(19px);
    background-color: ${props => props.theme.primary.normal};
    border: 1px solid #97aeff;
  }
`;

const StyledSpan = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  height: 17px;
  border-radius: 8px;
  background-color: #dcdcdc;
  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    top: -1px;
    left: 0px;
    border-radius: 50%;
    border: solid 1px rgba(155, 155, 155, 0.46);
    background-color: #dcdcdc;
    transition: 0.4s;
  }
`;

const Switch = ({ label, ...props }) => {
  const {
    media: { mobile },
  } = useContext(Context);
  return (
    <Container
      display="flex"
      flexDirection="row"
      alignItems="center"
      marginBottom={mobile ? 'xsmall' : 'normal'}
      height={mobile ? '42px' : '46px'}
    >
      <Label label={label} />
      <StyledLabel>
        <StyledInput type="checkbox" {...props} />
        <StyledSpan />
      </StyledLabel>
    </Container>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
};

Switch.defaultProps = {
  label: null,
};

export default Switch;
