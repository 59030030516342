const defaults = require('./defaults');

const { port, auth } = defaults;

module.exports = {
  ...defaults,
  favicon: true,
  logger: {
    console: {
      level: 'debug',
    },
  },
  token: {
    ...defaults.token,
    cookie: {
      domain: 'localhost',
    },
  },
  auth: {
    ...auth,
    domain: 'ls-dashboard-staging.au.auth0.com',
    audience: 'https://services.lsapis.com.au',
    clientID: 'WhJmbUnIZpRkIC3Xp2rHG5xJ190nO8gz',
    redirectUri: `http://localhost:${port}`,
  },
  api: 'https://staging.services.lsapis.com.au',
};
