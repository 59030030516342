import { useMutation } from '@apollo/client';
import {
  Button,
  Container,
  Panel,
  Text,
} from 'ls-common-client/src/components';
import { formatLS } from 'ls-common-client/src/phone';
import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Context } from '../../../../contextV2/AppContext';
import createListingMutation from '../../../../graphql/mutations/createListing';
import Label from '../../../UI/atoms/Label';
import Validator from '../../../UI/atoms/Validator';

const transformPayload = (user, values) => {
  const { name, phone, email } = values.businessDetails;

  const MOBILE_REGEX =
    '^(0[4-5][0-9]{2,3})(| )[0-9]{3}(| )[0-9]{3}$|(^61(| )[4-5][0-9]{0,4})(| )[0-9]{3,4}(| )[0-9]{3,4}$';
  let mainContactType = 'phone';
  const mobileRgx = new RegExp(MOBILE_REGEX, 'gim');
  if (mobileRgx.test(phone)) {
    mainContactType = 'mobile';
  }

  const { id: userId, account } = user || {};
  const { firstName, lastName, email: loggedInEmail } = account || {};

  const payload = {
    input: {
      userId,
      name: `${firstName} ${lastName}`,
      email: loggedInEmail || `${firstName}.${lastName}@localsearch.com.au`,
      listing: {
        name,
        paid: false,
        address: {
          ...values.location,
          suburbId: -1,
          market: values.location.suburb,
          region: values.location.suburb,
        },
        mainContacts: [
          {
            value: phone,
            type: mainContactType,
          },
        ],
        additionalContacts: [
          {
            value: email,
            type: 'email',
          },
        ],
      },
    },
  };
  // Remove unused
  delete payload.input.specialist;
  delete payload.input.terms;
  return payload;
};

const RenderRow = (label, text) =>
  text && (
    <Container display="flex" alignItems="center" padding="5px 0">
      {label && <Label margin="0 5px 0 0">{label}:</Label>}
      {text && <Text>{text}</Text>}
    </Container>
  );

function Step2() {
  const {
    form: { formState, setFormState },
    user: { user },
    tabs: { setActiveTab },
  } = useContext(Context);

  const [
    createListing,
    { data, loading: createListingLoading, error: createListingError },
  ] = useMutation(createListingMutation);

  const submitListing = async () => {
    const payload = transformPayload(user || null, formState);
    await createListing({ variables: payload });
  };

  useEffect(() => {
    if (data && data.createListing.success) {
      setFormState(null);
      setActiveTab(0);
    }
  }, [data]);

  if (data && data.createListing.success) {
    return <Redirect to="/success" />;
  }

  return formState ? (
    <Container display="flex" flexDirection="column">
      <Panel margin="20px 0 0 0" header="Business Details">
        <Container padding="5px 20px" display="flex" flexDirection="column">
          {RenderRow('Name', formState.businessDetails.name)}
          {RenderRow('Email', formState.businessDetails.email)}
          {RenderRow('Phone', formatLS(formState.businessDetails.phone))}
        </Container>
      </Panel>

      <Panel margin="20px 0 0 0" header="Business Location">
        <Container padding="5px 20px" display="flex" flexDirection="column">
          {RenderRow('Street', formState.location.streetAddress)}
          {RenderRow('Suburb', formState.location.suburb)}
          {RenderRow('State', formState.location.state)}
          {RenderRow('Postcode', formState.location.postcode)}
        </Container>
      </Panel>
      <Button
        marginLeft="auto"
        width="auto"
        marginTop="15px"
        primary
        rounded
        disabled={createListingLoading}
        loading={createListingLoading}
        onClick={submitListing}
      >
        Submit
      </Button>

      {createListingError && createListingError.message && (
        <Validator>Error: {createListingError.message}</Validator>
      )}
    </Container>
  ) : null;
}

export default Step2;
