const { NODE_ENV: env = 'local', PORT: port = 8080 } = process.env;

module.exports = {
  env,
  port,
  debug: true,
  mode: 'development',
  sourceMap: true,
  hot: true,
  favicon: true,
  title: 'Digital Presence',
  imageResizeRoot: 'https://d1zmvcyjeoeklt.cloudfront.net',
  api: 'https://development.services.lsapis.com.au',
  googleMapsApiKey: 'AIzaSyD1scZ4h2kbYhTIMS4zFiiKRDD4wlDPBb0',
  auth: {
    responseType: 'token id_token',
    scope: 'openid',
  },
  token: {
    tolerance: 5 * 60 * 1000, // 5 minutes
    cookie: { domain: '.localsearch.cloud' },
  },
  logger: {
    sentry: {
      level: 'error',
      dsn:
        'https://02a7fff828d14bfe8fc2c69c734cd2b4@o292297.ingest.sentry.io/6008058',
      tracesSampleRate: 1.0,
      environment: env,
    },
    console: {
      level: 'debug',
    },
  },
};
