import React from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';

const StyledAutoSuggestWrapper = styled(Container)`
  width: 100%;
  > div {
    top: 46px;
    z-index: 100;
  }
`;

const AutoSuggestWrapper = props => {
  return (
    <StyledAutoSuggestWrapper>
      <AutoSuggest {...props} />
    </StyledAutoSuggestWrapper>
  );
};

export default AutoSuggestWrapper;
