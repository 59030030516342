import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Button from 'ls-common-client/src/components/Button';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import Ghost from '../../images/Ghost';
import GhostBackground from '../../images/GhostBackground';
import { Context } from '../../contextV2/AppContext';

const animation = keyframes`
  0% {
    top: 0%;
  }
  30% {
    top: 15%;
  }
  50% {
    top: 0%;
  }
  70% {
    top: 20%;
  }
  100% {
    top: 0%;
  }
`;

const GhostAnimation = styled(Container)`
  animation: ${animation} 8s infinite;
`;

const NotFound = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  const history = useHistory();
  return (
    <Container
      display="flex"
      minHeight="100%"
      padding="80px 20px"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        flexWrap="wrap-reverse"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Container minWidth="280px" maxWidth="390px">
          <Text color="text400" display="block" fontSize="18px">
            404
          </Text>
          <Text
            marginBottom={mobile ? '20px' : '30px'}
            fontSize={mobile ? '42px' : '48px'}
            fontWeight="bold"
            lineHeight="1.2"
            display="block"
          >
            Whoops, you’ve hit a deadend.
          </Text>
          <Text
            color="text400"
            display="block"
            fontSize="18px"
            marginBottom="35px"
          >
            The good news is you’ve found our friend Gert the Ghost! Head{' '}
            <AnchorLink to="/">back</AnchorLink> to get back on track.
          </Text>
          <Button
            height="48px"
            fontSize="18px"
            width="auto"
            padding="0 40px"
            rounded
            border
            primary
            onClick={() => history.push('/')}
          >
            Back to safety
          </Button>
        </Container>
        <Container
          padding="0 40px"
          justifyContent="center"
          alignItems="center"
          minWidth="315px"
          maxWidth="420px"
          marginBottom="30px"
          flex="1"
          display="flex"
        >
          <Container maxWidth="420px" width="100%" position="relative">
            <GhostAnimation position="absolute" width="100%" left="0" top="0">
              <Ghost width="100%" />
            </GhostAnimation>
            <GhostBackground width="100%" />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default NotFound;
