import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import AppMenu from 'ls-common-client/src/components/AppMenu';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import Button from 'ls-common-client/src/components/Button';
import Icon from 'ls-common-client/src/components/Icon';
import { auth, imageResizer } from '../../services';
import localsearchLogo from '../../images/localsearchLogo.svg';
import LocalLogo from '../../images/local';
import { Context } from '../../contextV2/AppContext';

const StyledHeader = styled(Container)`
  height: 70px;
  background-image: ${props => props.theme.custom.backgroundImage.gradient};
`;

const StyledButton = styled(Button)`
  height: 30px;
  padding: 5px 10px 5px 11px;
  border-radius: 15px;
  background-color: #ffffff;
  color: ${props => props.theme.primary.primary300};
  font-weight: normal;
`;

const Header = () => {
  const {
    user: { user },
    media: { mobile },
  } = useContext(Context);

  const { isStaff, isPersoniv } = user;

  return (
    <StyledHeader
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection={mobile ? 'column' : 'row'}
    >
      <Container
        flex="1"
        padding={mobile ? '10px 15px' : '10px'}
        maxWidth="1060px"
        width="100%"
        flexDirection="row"
        display="flex"
      >
        <Container
          marginRight={mobile ? 'small' : 'medium'}
          display="flex"
          alignItems="center"
        >
          {mobile ? (
            <LocalLogo width={40} />
          ) : (
            <img alt="Digital Presence Logo" src={localsearchLogo} />
          )}
        </Container>
        <Container display="flex" alignItems="center" justifyContent="center">
          <StyledButton
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              className="ls-icon icon-generaledit"
              fontSize="20px"
              marginRight="small"
            />{' '}
            Staff Portal
          </StyledButton>
        </Container>
        <Container
          marginLeft="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <AppMenu
            iconFill="#ffffff"
            isStaff={!!isStaff}
            isPersoniv={!!isPersoniv}
            width="34px"
            height="34px"
          />
          <UserMenu
            user={user}
            auth={auth}
            iconFill="#ffffff"
            marginLeft
            imageResizer={imageResizer}
            width="34px"
            height="34px"
          />
        </Container>
      </Container>
    </StyledHeader>
  );
};
export default Header;
