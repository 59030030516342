import React, { useContext } from 'react';
import Container from 'ls-common-client/src/components/Container';
import PropTypes from 'prop-types';
import { Context } from '../../contextV2/AppContext';

const PanelContent = ({ children, ...props }) => {
  const {
    media: { mobile, tablet },
  } = useContext(Context);
  return (
    <Container
      {...props}
      // eslint-disable-next-line no-nested-ternary
      padding={mobile ? '10px 15px' : tablet ? '20px' : '20px 35px'}
    >
      {children}
    </Container>
  );
};

PanelContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PanelContent;
