import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const create = ({ api, auth, logger }) => {
  const httpLink = new HttpLink({
    uri: `${api}/graphql`,
  });

  const authentication = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `bearer ${auth.token()}`,
      },
    });
    return forward(operation);
  });

  const authError = onError(err => {
    const { graphQLErrors, operation } = err;
    const { operationName } = operation || {};

    if (graphQLErrors) {
      for (let index = 0; index < graphQLErrors.length; index += 1) {
        const {
          extensions: { exception },
        } = graphQLErrors[index];
        const error = exception || {};
        const { status } = error;
        if ([401, 403].includes(status) && !operationName) {
          auth.login({
            redirectUrl: window.location.pathname,
          });
          break;
        }
        if ([400, 404].includes(status)) {
          logger.warn(error);
        } else {
          logger.error(error);
        }
      }
    }
  });

  const client = new ApolloClient({
    link: from([authError, authentication, httpLink]),
    cache: new InMemoryCache(),
  });
  return client;
};

export { create };
