import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'ls-common-client/src/components/Container';
import Label from './Label';
import { Context } from '../../contextV2/AppContext';

const StyledEmptyLabel = styled(Container)`
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 46px;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 16px;
  @media ${props => props.theme.mediaQueries.mobile} {
    display: none;
  }
`;

const InputLabelWrapper = ({ label, emptyLabel, children }) => {
  const {
    media: { mobile },
  } = useContext(Context);

  return (
    <Container
      display="flex"
      flexDirection={mobile ? 'column' : 'row'}
      alignItems="flex-start"
      marginBottom="small"
    >
      {!!label && <Label label={label} />}
      {!!emptyLabel && <StyledEmptyLabel />}
      <Container width="100%">{children}</Container>
    </Container>
  );
};

InputLabelWrapper.propTypes = {
  children: PropTypes.node,
  emptyLabel: PropTypes.bool,
  label: PropTypes.string,
};

InputLabelWrapper.defaultProps = {
  children: null,
  emptyLabel: null,
  label: null,
};

export default InputLabelWrapper;
