import React, { memo } from 'react';
import AnimationCommon from 'ls-common-client/src/components/Animation';

const Animation = memo(
  ({ ...props }) => {
    return <AnimationCommon {...props} />;
  },
  (prevProps, nextProps) => prevProps.tick === nextProps.tick
);

export default Animation;
