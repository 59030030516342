const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  imageResizeRoot: 'https://d2xzxktm3qm7rt.cloudfront.net',
  api: 'https://services.lsapis.com.au',
  token: {
    ...defaults.token,
    cookie: {
      domain: '.localsearch.com.au',
    },
  },
  auth: {
    ...auth,
    domain: 'ls-dashboard.au.auth0.com',
    audience: 'https://services.lsapis.com.au',
    clientID: 'xps8bit9vrRqYsfvGdwPYP62lOlUFaqv',
    redirectUri: 'https://digital-presence.localsearch.com.au',
  },
  deploy: {
    domain: 'digital-presence.localsearch.com.au',
    subDomain: 'digital-presence.localsearch.com.au',
    aliases: 'digital-presence.localsearch.com.au',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/f7640e17-02cd-4c7e-b40a-df30c9fef0f2',
  },
  logger: {
    sentry: {
      level: 'error',
      dsn:
        'https://02a7fff828d14bfe8fc2c69c734cd2b4@o292297.ingest.sentry.io/6008058',
      tracesSampleRate: 1.0,
      environment: 'production',
    },
    console: {
      level: 'debug',
    },
  },
};
