import React from 'react';
import ReactDom from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { hot } from 'react-hot-loader/root';
import { HelmetProvider } from 'react-helmet-async';
import App from './components/App';
import { logger, auth, client } from './services';
import config from './config';

const { logger: { sentry = {} } = {} } = config || {};

Sentry.init({
  integrations: [new Integrations.BrowserTracing()],
  ...sentry,
});

logger.info(config.logger || {});

const Root = () => (
  <ApolloProvider client={client}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </ApolloProvider>
);

const render = async () => {
  const token = await auth.init();
  if (token) {
    ReactDom.render(<Root />, document.getElementById('root'));
  }
};

render();

export default hot(render);
