import { Button, Container } from 'ls-common-client/src/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormInputV2 from '../FormInputV2';
import LocationForm from './LocationForm';
import { Panel } from '../../../../common';

export default function Step1() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Container display="flex" flexDirection="column">
      <Panel margin="0" header="Business Details">
        <Container padding="20px">
          <FormInputV2
            label="Business Name *"
            id="name"
            error={
              errors &&
              errors.businessDetails &&
              errors.businessDetails.name &&
              errors.businessDetails.name.message
            }
            placeholder="Business Name"
            {...register('businessDetails.name')}
          />
          <Container marginTop="15px">
            <FormInputV2
              label="Phone *"
              id="phone"
              error={
                errors &&
                errors.businessDetails &&
                errors.businessDetails.phone &&
                errors.businessDetails.phone.message
              }
              placeholder="Phone Number"
              {...register('businessDetails.phone')}
            />
          </Container>

          <Container marginTop="15px">
            <FormInputV2
              label="Email *"
              id="email"
              type="email"
              placeholder="Email"
              error={
                errors &&
                errors.businessDetails &&
                errors.businessDetails.email &&
                errors.businessDetails.email.message
              }
              {...register('businessDetails.email')}
            />
          </Container>
        </Container>
      </Panel>

      <Panel margin="20px 0 0 0" header="Business Location">
        <Container padding="20px">
          <LocationForm />
        </Container>
      </Panel>

      <Button
        type="submit"
        marginLeft="auto"
        width="auto"
        marginTop="15px"
        primary
        rounded
      >
        Next
      </Button>
    </Container>
  );
}
