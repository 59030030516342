import React from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import PanelWrapper from 'ls-common-client/src/components/Panel';
import PropTypes from 'prop-types';

const StyledPanel = styled(PanelWrapper)`
  border-radius: 11px;
  border: solid 1px rgba(196, 196, 196, 0.3);
  background-color: #ffffff;
`;

const StyledHeader = styled(Container)`
  padding: 0 20px;
  &.isBlueHeader {
    background-image: ${props => props.theme.custom.backgroundImage.gradient};
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
  }
`;

const Panel = ({ header, children, isBlueHeader, active, ...props }) => {
  return (
    <StyledPanel
      header={
        header ? (
          <StyledHeader
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingHorizontal={!isBlueHeader}
            padding={isBlueHeader ? '10px 25px' : 0}
            minHeight="55px"
            borderBottom={theme => `1px solid ${theme.border.border300}`}
            className={`${isBlueHeader ? 'isBlueHeader' : ''} `}
          >
            <Text
              display="block"
              flex="1 0 auto"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontSize={isBlueHeader ? '20px' : 'normal'}
              fontWeight={isBlueHeader ? 'bold' : '600'}
              color={isBlueHeader ? '#ffffff' : 'text500'}
            >
              {header}
            </Text>
          </StyledHeader>
        ) : null
      }
      className={active ? 'active' : ''}
      {...props}
    >
      {children}
    </StyledPanel>
  );
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  isBlueHeader: PropTypes.bool,
  active: PropTypes.bool,
};

Panel.defaultProps = {
  header: null,
  isBlueHeader: false,
  active: false,
};

export default Panel;
