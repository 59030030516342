import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '../../../UI/atoms/Input';
import Label from '../../../UI/atoms/Label';
import Validator from '../../../UI/atoms/Validator';

const StyledFormInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled(Label)`
  margin-bottom: 10px;
`;

const StyledInput = styled(Input)`
  border: 1px solid ${props => props.theme.custom.colors.grey};
  border-radius: 9px;
  font-size: 16px;
  padding: 15px;
  width: 100%;
  margin-bottom: 5px;
`;

const FormInputV2 = React.forwardRef(({ label, id, error, ...props }, ref) => (
  <StyledFormInput>
    {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
    <StyledInput id={id} aria-invalid={!!error} ref={ref} {...props} />
    {error && <Validator role="alert">{error}</Validator>}
  </StyledFormInput>
));

FormInputV2.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
};

FormInputV2.defaultProps = {
  label: undefined,
  error: undefined,
};

export default FormInputV2;
