import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import poboxRegex from 'pobox-regex';
import { toE164, validate as validatePhone } from 'ls-common-client/src/phone';

import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'ls-common-client/src/components';
import Step1 from './Steps/Step1/Step1';
import Step2 from './Steps/Step2';
import { Context } from '../../../contextV2/AppContext';
import TabsOverview from './TabsOverview';

const StyledContainer = styled(Container)`
  transition: all ease 0.3s;
  display: none;
  visibility: hidden;
  width: 100%;
  &.isActive {
    display: block;
    visibility: visible;
  }
`;

const phoneValidator = (value, { message }) => {
  if (validatePhone(value)) {
    return toE164(value);
  }
  return message('Invalid phone number');
};

const locationSchema = Joi.object({
  streetAddress: Joi.string()
    .custom((value, { error }) => {
      if (poboxRegex().test(value)) {
        return error('pobox');
      }
      return value;
    })
    .messages({
      'string.empty': 'You forgot your street',
      pobox: 'PO Boxes are not allowed',
    }),
  suburb: Joi.string(),
  state: Joi.string()
    .required()
    .messages({
      'string.empty': 'You forgot your state',
      'any.required': 'You forgot your state',
    }),
  postcode: Joi.string()
    .required()
    .messages({
      'string.empty': 'You forgot your postcode',
    }),
});

const formSchema = Joi.object({
  businessDetails: Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'string.empty': 'Business Name Required',
      }),
    email: Joi.string()
      .required()
      .messages({
        'string.empty': 'Business Email Required',
      }),
    phone: Joi.string()
      .required()
      .messages({
        'string.empty': 'Business Phone Required',
      })
      .custom(phoneValidator),
  }),
  location: locationSchema,
});

const Home = () => {
  const {
    media: { mobile },
    tabs: { activeTab, setActiveTab },
    form: { setFormState },
  } = useContext(Context);

  const methods = useForm({
    resolver: joiResolver(formSchema),
  });
  const { handleSubmit } = methods;

  const onSubmit = values => {
    console.log(values);
    setFormState(values);
    setActiveTab(1);
  };

  return (
    <FormProvider {...methods}>
      <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
        <Container display="flex" flexWrap="wrap" justifyContent="center">
          <TabsOverview padding="15px" />
          <Container
            maxWidth="1060px"
            flex="1"
            padding="0 10px"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            marginTop={mobile ? '0px' : 'medium'}
          >
            <StyledContainer className={`${activeTab === 0 ? 'isActive' : ''}`}>
              <Step1 />
            </StyledContainer>
            <StyledContainer className={`${activeTab === 1 ? 'isActive' : ''}`}>
              <Step2 />
            </StyledContainer>
          </Container>
        </Container>
      </Form>
    </FormProvider>
  );
};

export default Home;
