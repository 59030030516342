import React from 'react';
import styled from 'styled-components';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';

const StyledTimeRangePicker = styled(TimeRangePicker)`
  margin-left: 5px;
  width: 100%;
  .react-timerange-picker__wrapper {
    border: none;
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }
  .react-timerange-picker__inputGroup {
    border-bottom: solid 1px #c4c4c4;
    padding-bottom: 5px;
    padding-right: 0;
    padding-left: 0;
    flex-grow: unset;
    display: flex;
    flex-direction: row;
    margin: 0 10px;

    @media ${props => props.theme.mediaQueries.mobile} {
      margin-left: 5px;
    }
  }
  .react-timerange-picker__inputGroup input,
  .react-timerange-picker__inputGroup__leadingZero {
    font-size: 14px;
    font-family: 'Circular';
    color: #4a4a4a;
  }
  .react-timerange-picker__inputGroup__leadingZero {
    padding-top: 1px;
  }
  .react-timerange-picker__inputGroup__input {
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    font-size: 14px;
  }
  .react-time-picker__inputGroup__divider {
    display: inline-block;
    margin: 0 2px;
    position: relative;
    top: -1px;
  }
  .react-timerange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-timerange-picker__inputGroup__input::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  .react-timerange-picker__inputGroup__input.react-timerange-picker__inputGroup__amPm {
    width: 30px;
    min-width: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  .react-timerange-picker__range-divider {
    width: 20px;
    display: inline-block;
    color: transparent;
    position: relative;
  }
  .react-timerange-picker__range-divider:before {
    content: 'To';
    position: absolute;
    color: #9b9b9b;
    transform: translate(-50%);
    font-size: 14px;
    left: 3px;
    @media ${props => props.theme.mediaQueries.mobile} {
      font-size: 12px;
      top: 4px;
      left: 5px;
    }
  }
  &.hasError {
    .react-timerange-picker__inputGroup {
      border-bottom: solid 1px ${props => props.theme.error.normal};
    }
  }
`;

const TimePicker = ({ ...props }) => {
  return (
    <StyledTimeRangePicker
      {...props}
      clearIcon={null}
      clockIcon={null}
      hourPlaceholder="00"
      minutePlaceholder="00"
      disableClock
      format="hh:mm a"
    />
  );
};

export default TimePicker;
