import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Text from 'ls-common-client/src/components/Text';
import Popup from 'ls-common-client/src/components/Popup';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Loader from 'ls-common-client/src/components/Loader';
import Icon from 'ls-common-client/src/components/Icon';
import Container from 'ls-common-client/src/components/Container';

const defaultRender = ({ onClick, ref, focused, label, value, key }) => (
  <EmptyButton
    key={key || value}
    onClick={onClick}
    ref={ref}
    display="block"
    width="100%"
    padding="5px"
  >
    <Text
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={focused ? '#eaefff' : 'none'}
      fontSize="16px"
      borderRadius="11px"
      padding="13px 10px 13px 20px"
      lineHeight="1.1"
      textAlign="left"
      color="normal"
      fontWeight="600"
      _hover={{
        backgroundColor: focused ? '#eaefff' : 'background200',
      }}
    >
      {label}
      <Text
        display="flex"
        alignItems="center"
        justifyContent="center"
        flex="0 0 15px"
        height="15px"
        borderRadius="100%"
        marginLeft="10px"
        backgroundColor={theme => theme.primary.normal}
        visibility={focused ? 'visible' : 'hidden'}
      >
        <Icon
          color="white"
          fontSize="9px"
          className="ls-icon icon-generalticksmall"
          marginBottom="-2px"
        />
      </Text>
    </Text>
  </EmptyButton>
);

const Selector = ({
  data,
  show,
  error,
  onChange,
  onClose,
  value,
  children,
  renderItem,
  anchor,
  popupProps,
  loading,
  ...props
}) => {
  const { ref: popupPropsRef, ...restPopupProps } = popupProps;

  const [isOutOfView, setIsOutOfView] = useState();
  const [matchIndex, setMatchIndex] = useState(null);
  const [opacity, setOpacity] = useState(0);
  const popupRef = popupPropsRef || useRef();
  const selectedRef = useRef();
  const buttonRef = useRef();
  const timeout = useRef();
  const keyText = useRef();

  const selected = useMemo(
    () => data.find(item => item.value === value) || {},
    [value]
  );

  const checkOutOfView = () => {
    const { innerHeight } = window;
    const { bottom } = popupRef.current.getBoundingClientRect();

    if (bottom > innerHeight) {
      setIsOutOfView(true);
    }
  };

  const setInitialMatchIndex = () => {
    setMatchIndex(data.findIndex(node => node.value === value));
  };

  const findMatch = () => {
    const match = data.findIndex(({ label }) => {
      const matchStr = label
        .replace(' ', '')
        .replace(':', '')
        .slice(0, keyText.current.length)
        .toLowerCase();

      return matchStr === keyText.current.toLowerCase();
    });

    setMatchIndex(i => (match !== -1 ? match : i));
  };

  const onArrowDown = () => {
    setMatchIndex(i => (i < data.length - 1 ? i + 1 : i));
  };

  const onArrowUp = () => {
    setMatchIndex(i => (i !== 0 ? i - 1 : i));
  };

  const onDelete = () => {
    keyText.current = keyText.current.slice(0, keyText.current.length - 1);
    findMatch();
  };

  const onValidKeydown = key => {
    keyText.current = `${keyText.current}${key}`;
    findMatch();
  };

  const keydown = useCallback(
    e => {
      const { code, key, which } = e;

      clearTimeout(timeout.current);

      // on space bar
      if (which === 32) {
        e.preventDefault();
      }

      // on tab
      if (which === 9) {
        onClose();
      }

      // on arrow down
      if (which === 40) {
        e.preventDefault();
        onArrowDown();
      }

      // on arrow up
      if (which === 38) {
        e.preventDefault();
        onArrowUp();
      }

      // on delete
      if (which === 8) {
        onDelete();
      }

      if (
        code.includes('Digit') ||
        code.includes('Key') ||
        code.includes('Numpad')
      ) {
        onValidKeydown(key);
      }

      timeout.current = setTimeout(() => {
        keyText.current = '';
      }, 2000);
    },
    [data]
  );

  const reset = () => {
    keyText.current = '';
    setOpacity(0);
    setIsOutOfView(false);
    setMatchIndex(null);
  };

  const onClickOutside = () => {
    if (!show) {
      return;
    }
    onClose();
  };

  const onItemClick = val => {
    onChange(val, buttonRef);
    onClose();
  };

  useEffect(() => {
    if (show) {
      checkOutOfView();
      setInitialMatchIndex();
      setOpacity(1);
      window.addEventListener('keydown', keydown);
    } else {
      reset();
      window.removeEventListener('keydown', keydown);
    }

    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, [show, data]);

  useEffect(() => {
    if (!selectedRef.current || !popupRef.current) {
      return;
    }

    const { offsetTop } = selectedRef.current;

    selectedRef.current.focus();
    popupRef.current.scrollTo(0, offsetTop);
  }, [matchIndex]);

  const isFocused = i => matchIndex === i;

  return (
    <ClickOutside type="button" onClickOutside={onClickOutside} {...props}>
      {typeof children === 'function'
        ? children({ ...selected, ref: buttonRef })
        : children}
      <Popup
        anchor={isOutOfView ? anchor.replace('top', 'bottom') : anchor}
        show={show}
        ref={popupRef}
        overflow="auto"
        opacity={opacity}
        marginTop="0"
        height="auto"
        maxHeight="300px"
        width="190px"
        {...restPopupProps}
      >
        {loading && (
          <Container
            width="100%"
            height="100%"
            position="relative"
            minHeight="150px"
          >
            <Loader
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%, -50%)"
              width="170px"
            />
          </Container>
        )}
        {data.map((item, i) =>
          renderItem({
            ...item,
            focused: isFocused(i),
            ref: isFocused(i) ? selectedRef : null,
            onClick: () => onItemClick(item.value),
          })
        )}
      </Popup>
    </ClickOutside>
  );
};

Selector.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ]),
      label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    })
  ),
  show: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  renderItem: PropTypes.func,
  anchor: PropTypes.string,
  popupProps: PropTypes.shape({
    ref: PropTypes.shape({}),
  }),
  loading: PropTypes.bool,
};

Selector.defaultProps = {
  data: [],
  show: false,
  error: false,
  onChange: () => {},
  onClose: () => {},
  value: null,
  children: null,
  renderItem: defaultRender,
  anchor: 'top',
  popupProps: {
    ref: null,
  },
  loading: false,
};

export default Selector;
