import React, { useContext, useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Button from 'ls-common-client/src/components/Button';
import Animation from 'ls-common-client/src/components/Animation';
import { hotdog } from '../../animations';
import { Context } from '../../contextV2/AppContext';

const Success = () => {
  const {
    media: { mobile },
  } = useContext(Context);

  const [redirect, setRedirect] = useState();
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
    }, 5000);
    return () => {};
  }, []);

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <Container
      display="flex"
      minHeight="100%"
      padding={mobile ? '30px 20px' : '80px 20px'}
      alignItems="center"
      justifyContent="center"
    >
      <Container
        flexWrap="wrap-reverse"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Container
          maxWidth="621px"
          backgroundColor="#fff"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="30px"
          borderRadius="11px"
          boxShadow="0 0 20px 0 rgba(0, 0, 0, 0.2)"
        >
          <Text
            marginBottom={mobile ? '20px' : '30px'}
            fontSize={mobile ? '30px' : '50px'}
            fontWeight="bold"
            lineHeight="1.2"
            display="block"
            textAlign="center"
          >
            Listing Added!
          </Text>
          <Text
            marginBottom={mobile ? '0' : '10px'}
            fontSize={mobile ? '16px' : '20px'}
            maxWidth="390px"
            lineHeight="1.2"
            display="block"
            textAlign="center"
          >
            You&apos;re helping the Localsearch family grow! You can expect this
            new profile to be live within 5 minutes.
          </Text>
          <Animation
            flex={mobile ? '0 0 190px' : '0 0 275px'}
            data={hotdog}
            width={mobile ? '200px' : '275px'}
          />
          <Button
            height="44px"
            fontSize="18px"
            width="260px"
            padding="0 40px"
            fontWeight="500"
            marginBottom={mobile ? '10px' : '50px'}
            rounded
            primary
            onClick={() => history.push('/')}
          >
            Add Another
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

export default Success;
