import React from 'react';
import PropTypes from 'prop-types';

function LocalLogo({ width, height }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 88.25 51.09"
      width={width}
      height={height}
    >
      <path
        fill="#fff"
        d="M0,44.8V11.91H6V44.8Z"
        transform="translate(0 -11.91)"
      />
      <path
        fill="#fff"
        d="M82.14,44.8V11.91h6V44.8Z"
        transform="translate(0 -11.91)"
      />
      <path
        fill="#fff"
        d="M58.84,38.6a6.59,6.59,0,0,1,.54-2.66,6.09,6.09,0,0,1,1.44-2,6.79,6.79,0,0,1,2.11-1.35,11.83,11.83,0,0,1,2.62-.72l5.5-.8a2,2,0,0,0,1.32-.56,1.47,1.47,0,0,0,.36-1,2.67,2.67,0,0,0-.92-2.05,4.3,4.3,0,0,0-6.08.41,4.24,4.24,0,0,0-1,2.34L59.34,29a7.16,7.16,0,0,1,.83-2.63A8.14,8.14,0,0,1,62,24a9.59,9.59,0,0,1,3-1.66,11.33,11.33,0,0,1,4-.64,12.92,12.92,0,0,1,4.49.72,8.55,8.55,0,0,1,3,1.88A6.9,6.9,0,0,1,78.13,27a10.37,10.37,0,0,1,.51,3.22v11a16.15,16.15,0,0,0,.08,1.93c.08.68.12,1.22.18,1.62H73.36c-.06-.3-.11-.71-.16-1.24s-.06-1-.06-1.44a7.83,7.83,0,0,1-2.48,2.31,7.7,7.7,0,0,1-4.12,1,8.55,8.55,0,0,1-3.2-.58,7.42,7.42,0,0,1-2.42-1.51,6.38,6.38,0,0,1-2-4.66Zm8.94,2.26a7.15,7.15,0,0,0,1.84-.24,3.84,3.84,0,0,0,1.57-.86,4.26,4.26,0,0,0,1.1-1.58,6.54,6.54,0,0,0,.42-2.48v-1l-5,.76a3.87,3.87,0,0,0-2,.83,2.4,2.4,0,0,0-.8,2,2.52,2.52,0,0,0,.72,1.8A2.8,2.8,0,0,0,67.78,40.86Z"
        transform="translate(0 -11.91)"
      />
      <path
        fill="#fff"
        d="M31.85,28.73v-.1a11,11,0,0,0-6.16-6.22A12.17,12.17,0,0,0,21,21.5a12.39,12.39,0,0,0-4.66.87,11.43,11.43,0,0,0-3.73,2.44,11.14,11.14,0,0,0-2.47,3.74,12.32,12.32,0,0,0-.9,4.76,12.27,12.27,0,0,0,.86,4.75,11.55,11.55,0,0,0,2.44,3.76,11.22,11.22,0,0,0,3.71,2.49,12.19,12.19,0,0,0,4.66.9,12.14,12.14,0,0,0,4.65-.86,11.26,11.26,0,0,0,3.74-2.46,11.45,11.45,0,0,0,2.47-3.74.21.21,0,0,1,0-.11,16.2,16.2,0,0,1-.65-4.64A16.44,16.44,0,0,1,31.85,28.73Zm-5.7,7.42a6.13,6.13,0,0,1-1.25,2,5.06,5.06,0,0,1-1.83,1.2,5.71,5.71,0,0,1-4.26,0A5.32,5.32,0,0,1,17,38.09a6.1,6.1,0,0,1-1.24-2,7.3,7.3,0,0,1-.46-2.77,7.25,7.25,0,0,1,.48-2.77,6,6,0,0,1,1.26-2,5.07,5.07,0,0,1,1.82-1.2A5.79,5.79,0,0,1,21,27a5.58,5.58,0,0,1,2.13.42,5.14,5.14,0,0,1,1.81,1.21,6.13,6.13,0,0,1,1.25,2,7.3,7.3,0,0,1,.46,2.77A7.4,7.4,0,0,1,26.15,36.15Z"
        transform="translate(0 -11.91)"
      />
      <path
        fill="#fff"
        d="M46.29,27.19a5.93,5.93,0,0,0-2.21.4,5.22,5.22,0,0,0-1.8,1.2,5.91,5.91,0,0,0-1.23,2,8,8,0,0,0,0,5.36,5.61,5.61,0,0,0,1.24,2,5.22,5.22,0,0,0,1.81,1.24,5.92,5.92,0,0,0,2.2.41,5.26,5.26,0,0,0,2-.33,5,5,0,0,0,1.53-.88,4.69,4.69,0,0,0,1-1.22,5.76,5.76,0,0,0,.6-1.37l5.31,1.79A9.88,9.88,0,0,1,53.35,43a10.75,10.75,0,0,1-3.09,1.71,11.57,11.57,0,0,1-4,.65,12.14,12.14,0,0,1-4.61-.91A11.05,11.05,0,0,1,37.92,42a11.53,11.53,0,0,1-2.49-3.76,12.26,12.26,0,0,1-.89-4.75,12.52,12.52,0,0,1,.91-4.76A11.14,11.14,0,0,1,37.92,25a11.42,11.42,0,0,1,3.69-2.44,12.61,12.61,0,0,1,8.63-.19A11.51,11.51,0,0,1,53.34,24a9.08,9.08,0,0,1,2.13,2.41,10.79,10.79,0,0,1,1.17,2.78L51.22,31a6,6,0,0,0-1.55-2.59A4.57,4.57,0,0,0,46.29,27.19Z"
        transform="translate(0 -11.91)"
      />
      <path
        fill="#ffcc00"
        d="M88.25,63H0V57H88.25Z"
        transform="translate(0 -11.91)"
      />
    </svg>
  );
}

LocalLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

LocalLogo.defaultProps = {
  width: 176,
  height: 34,
};

export default LocalLogo;
