import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Text from 'ls-common-client/src/components/Text';
import { useFormContext } from 'react-hook-form';
import smoothScroll from '../../../lib/smoothScroll';
import { Context } from '../../../contextV2/AppContext';

const StyledNumber = styled(Text)`
  width: 27px;
  height: 27px;
  padding: 3px 10px 4px;
  color: #fff;
  border-radius: 100%;
  background-color: ${props => props.theme.custom.colors.grey};
  margin-right: 10px;
  font-size: 15px;
`;

const StyledButton = styled(EmptyButton)`
  position: relative;
  z-index: 2;
  color: ${props => props.theme.shade.shade700};
  &:hover:not([disabled]),
  &.active:not([disabled]) {
    color: ${props => props.theme.primary.normal};
    .tabNumber {
      background-image: ${props => props.theme.custom.backgroundImage.gradient};
    }
  }
  &.completed {
    color: ${props => props.theme.text.normal};
    .tabNumber {
      background-color: ${props => props.theme.text.normal};
    }
  }
  &[disabled] {
    cursor: default;
  }
  &.tablet {
    margin-right: 18px;
  }
  &.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 22px 0;
    min-width: 25%;
    margin: 0;

    position: relative;
    .tabNumber {
      margin-bottom: 10px;
      margin-right: 0;
      position: relative;
      z-index: 3;
    }
    &:before {
      content: ' ';
      width: 93%;
      height: 1px;
      border-top: 2px dashed ${props => props.theme.shade.shade700};
      position: absolute;
      top: 34px;
      z-index: 1;
      left: 42px;
    }
    &:hover:not([disabled]),
    &.active:not([disabled]) {
      &:before {
        border-top: 2px dashed ${props => props.theme.primary.normal};
      }
    }
    &.completed {
      &:before {
        border-top: 2px dashed ${props => props.theme.text.normal};
      }
    }
    &:last-child {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
`;

const TabsOverview = () => {
  const {
    media: { mobile, tablet },
    tabs: { activeTab, setActiveTab },
  } = useContext(Context);

  const {
    formState: { isValid },
  } = useFormContext();

  const tabs = [
    {
      name: 'Business Information',
    },
    {
      name: 'Preview',
      disabled: !isValid,
      type: 'submit',
    },
  ];

  useEffect(() => {
    smoothScroll(document.querySelector(`.TabsOverview`), 66);
  }, [activeTab]);

  return (
    <Container
      backgroundColor="#ffffff"
      width="100%"
      display="flex"
      height={mobile ? '110px' : '60px'}
      justifyContent="center"
      className="TabsOverview"
    >
      <Container
        display="flex"
        flexDirection="row"
        maxWidth="1060px"
        justifyContent={mobile ? 'center' : 'flex-start'}
        flex="1"
        padding="0 10px"
      >
        {tabs.map(({ name, disabled, type }, index) => (
          <StyledButton
            key={name}
            marginRight={mobile ? '' : '30px'}
            className={`${activeTab === index ? 'active' : ''} ${
              index < activeTab ? 'completed' : ''
            } ${mobile ? 'mobile' : ''} ${tablet ? 'tablet' : ''}`}
            onClick={() => setActiveTab(index)}
            type={type}
            disabled={disabled}
          >
            <StyledNumber
              className={`tabNumber ${activeTab === index ? 'active' : ''}`}
            >
              {index + 1}
            </StyledNumber>
            <Text
              fontSize={mobile ? 'xsmall' : 'normal'}
              fontWeight={mobile ? '600' : 'normal'}
            >
              {name}
            </Text>
          </StyledButton>
        ))}
      </Container>
    </Container>
  );
};

export default TabsOverview;
