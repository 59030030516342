import React from 'react';
import styled from 'styled-components';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Icon from 'ls-common-client/src/components/Icon';

const StyledEmptyButton = styled(EmptyButton)`
  position: absolute;
  right: 0;
  top: 14px;
  @media ${props => props.theme.mediaQueries.mobile} {
    top: 10px;
  }
`;

const StyledIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  border: solid 1px ${props => props.theme.error.normal};
  color: ${props => props.theme.error.normal};
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    position: relative;
    left: 1px;
    top: 1px;
  }
`;

const InputRemoveButton = ({ ...props }) => {
  return (
    <StyledEmptyButton className="InputRemoveButton" type="button" {...props}>
      <StyledIcon className="ls-icon icon-generalminuslarge" fontSize="35px" />
    </StyledEmptyButton>
  );
};

export default InputRemoveButton;
