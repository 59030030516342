const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  logger: {
    console: {
      level: 'debug',
    },
  },
  auth: {
    ...auth,
    domain: 'ls-dashboard-staging.au.auth0.com',
    audience: 'https://services.lsapis.com.au',
    clientID: 'WhJmbUnIZpRkIC3Xp2rHG5xJ190nO8gz',
    redirectUri: 'https://development.ls-digital-presence.localsearch.cloud',
  },
  deploy: {
    domain: 'ls-digital-presence.localsearch.cloud',
    subDomain: 'development.ls-digital-presence.localsearch.cloud',
    aliases: 'development.ls-digital-presence.localsearch.cloud',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/d579e7ab-1950-4d86-a9e6-3a965e33c32c',
  },
};
