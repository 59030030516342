import React from 'react';

const GhostBackground = props => (
  <svg viewBox="0 0 346 189" {...props}>
    <defs>
      <path
        id="prefix__a"
        d="M0 61.553C0 95.379 77.457 122.8 173.006 122.8c95.548 0 173.005-27.421 173.005-61.247 0-33.827-77.457-61.248-173.005-61.248C77.457.305 0 27.726 0 61.553"
      />
      <path
        id="prefix__c"
        d="M2.83.283a2.657 2.657 0 000 5.313h5.488a1.84 1.84 0 011.838 1.838c0 3.943 3.208 7.15 7.152 7.15h4.737a2.656 2.656 0 100-5.312h-4.737a1.842 1.842 0 01-1.84-1.838c0-3.944-3.207-7.151-7.15-7.151H2.83z"
      />
      <path
        id="prefix__e"
        d="M26.861.003C12.739 0 .793 11.858.449 25.926.122 40.994.339 55.873 2.834 70.771a143.283 143.283 0 004.62 17.374c.805 2.265 3.408 4.097 5.694 4.097h.416c2.286 0 3.298-1.795 2.4-4.022-.612-1.52.165-2.74 1.673-2.72 1.508.022 3.204 1.265 3.857 2.768.958 2.204 3.712 3.974 5.998 3.974h.416c2.286 0 3.147-1.733 2.098-3.899-.716-1.479-.01-2.67 1.498-2.649 1.508.022 3.275 1.236 4.032 2.697 1.11 2.142 4.015 3.851 6.301 3.851h.415c2.286 0 2.996-1.672 1.795-3.776-.82-1.436-.184-2.599 1.324-2.578 1.508.022 3.347 1.206 4.207 2.626 1.26 2.08 4.317 3.728 6.604 3.728h.208c2.4 0 2.985-1.691 1.576-3.84-3.137-5.206-5.367-10.322-7.07-16.143-3.37-15.257-1.958-30.34-.228-45.72C52.368 12.109 41.375.003 26.872.003h-.01"
      />
      <linearGradient id="prefix__f" x1="50%" x2="50%" y1="-45.26%" y2="100%">
        <stop offset="0%" stopColor="#98B0FD" />
        <stop offset="100%" stopColor="#2D60FB" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="#97AEFF">
        <path d="M284.78 38.857a2.405 2.405 0 00-4.75.743c.344 2.24.784 9.255-1.637 12.078a3.238 3.238 0 01-1.951 1.122l.22-13.36a3.476 3.476 0 10-6.948.028l.627 25.355c-.833-.135-1.491-.501-2.033-1.133-2.42-2.823-1.982-9.838-1.636-12.077a2.404 2.404 0 10-4.751-.743c-.173 1.104-1.563 10.926 2.731 15.943 1.477 1.725 3.476 2.703 5.81 2.855l.273 11.037h5.246l.38-23.058c2.282-.178 4.237-1.15 5.688-2.847 4.293-5.017 2.904-14.839 2.731-15.942" />
        <path
          fillOpacity={0.73}
          d="M60.78 60.351a1.531 1.531 0 10-3.027.474c.22 1.427.5 5.9-1.044 7.699-.334.39-.737.623-1.244.715l.141-8.517a2.216 2.216 0 10-4.43.018l.4 16.163a2.066 2.066 0 01-1.296-.722c-1.543-1.8-1.263-6.271-1.043-7.698a1.532 1.532 0 10-3.028-.474c-.11.703-.996 6.965 1.74 10.163.942 1.1 2.216 1.723 3.704 1.82l.175 7.035h3.344l.242-14.698c1.455-.113 2.7-.734 3.626-1.814 2.737-3.198 1.85-9.46 1.74-10.164"
        />
      </g>
      <g transform="translate(0 65.997)">
        <mask id="prefix__b" fill="#fff">
          <use xlinkHref="#prefix__a" />
        </mask>
        <path
          fill="#EAEFFF"
          d="M0 61.553C0 95.379 77.457 122.8 173.006 122.8c95.548 0 173.005-27.421 173.005-61.247 0-33.827-77.457-61.248-173.005-61.248C77.457.305 0 27.726 0 61.553"
          mask="url(#prefix__b)"
        />
      </g>
    </g>
  </svg>
);

export default GhostBackground;
