import * as theme from 'ls-common-client/src/themes/default';

const colors = {
  orange: '#ed8a39',
  lightBlue: '#e9efff',
  lightGreen: '#eaf5f4',
  lightRed: '#f6eeee',
  lightOrange: '#fdf7f2',
  grey: '#c4c4c4',
  primary: '#4a4a4a',
};

const backgroundImage = {
  gradient: 'linear-gradient(to bottom, #98b0fd -45%, #2d60fb)',
};

export default {
  ...theme,
  custom: {
    colors,
    backgroundImage,
  },
  mediaQueries: {
    ...theme.mediaQueries,
    tablet: '(max-width: 1366px)',
    tabletPortraitOnly: '(max-width: 1024px) and (orientation: portrait)',
  },
};
