import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LabelWrapper from 'ls-common-client/src/components/Label';

const StyledLabel = styled(LabelWrapper)`
  width: 125px;
  min-width: 125px;
  max-width: 125px;
  height: 46px;
  display: flex;
  align-items: center;
  font-size: 16px;
  @media ${props => props.theme.mediaQueries.mobile} {
    font-size: 13px;
    height: 38px;
    width: auto;
    min-width: 60px;
  }
`;

const Label = ({ label }) => {
  return !!label && <StyledLabel marginRight="normal">{label}</StyledLabel>;
};

Label.propTypes = {
  label: PropTypes.string,
};

Label.defaultProps = {
  label: null,
};

export default Label;
